import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { HeaderLogoWrap } from "../GobalStyle";
import { iBeorLogo } from "../Utils/Images";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";

export default function ContactUs() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <ContactWrapper>
        <ContactContainer>
          <HeaderLogoWrap>
            <img
              className="ContactUsLogo"
              src={iBeorLogo}
              alt="LOGO"
              onClick={() => navigate("/")}
            />
          </HeaderLogoWrap>
        </ContactContainer>
        <GreenDiv>
          <ContactContainer>
            <h1>Contact Us</h1>

            <h2>
              Got something you want to talk about? Contact us or email us and
              we promise to get back to you as soon as we can.
            </h2>

            {/* <h3>Partnerships</h3>

            <h4>Interested in partnering with iBeor?</h4>

            <h5>partners@ibeor.com</h5> */}
          </ContactContainer>
        </GreenDiv>

        <ContactContainer>
          <HelpBox>
            <ContactContainer>
              <h1>How can we help?</h1>

              <div>
                <label>Choose a Topic *</label>
                <select>
                  <option selected disabled hidden></option>
                  <option>Ad Sales</option>
                  <option>Success Stories</option>
                  <option>Help / Support</option>
                  <option>Question about something else</option>
                </select>
              </div>

              <div style={{ margin: "20px 0" }}>
                <label>Tell us what you need help with</label>
                <textarea />
              </div>

              <div style={{ margin: "20px 0" }}>
                <button>Send</button>
              </div>
            </ContactContainer>
          </HelpBox>
        </ContactContainer>
      </ContactWrapper>

      {size >= 500 && <Footer />}
    </div>
  );
}

const HelpBox = styled.div`
  width: 100%;
  height: 100%;
  background: #f6ebe4;
  padding: 0 0 60px 0;
  margin: 0 0 73px 0;

  h1 {
    color: #624c40;
    text-align: center;
    font-size: 25px;
    font-family: "Noto Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 50px 0;
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      font-family: "Noto Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
    }

    select {
      width: 100%;
      height: 62px;
      border-radius: 12px;
      padding: 0 12px;
      box-shadow: 7px 7px #e4bfad;
      font-size: 18px;
      font-family: "Noto Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
    }

    select:hover {
      border: 2px solid #624c40;
    }

    textarea {
      width: 100%;
      height: 271px;
      border-radius: 12px;
      padding: 20px;
      box-shadow: 7px 7px #e4bfad;
      font-size: 18px;
      font-family: "Noto Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
    }

    @media (max-width: 500px) {
      h1 {
        padding: 30px 0 20px 0;
      }
      select {
        height: 52px;
        font-size: 14px;
      }
      textarea {
        height: 180px;
        font-size: 14px;
      }
    }

    textarea:hover {
      border: 2px solid #624c40;
    }

    button {
      width: 108px;
      height: 52px;
      border-radius: 12px;
      background: #b77a5c;
      color: #583700;
      font-size: 14px;
      font-weight: 500;
      font-family: "Noto Sans", sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      padding: 10px;
      color: #583700;
      cursor: pointer;
      margin: 0 auto;
    }
    button:hover {
      opacity: 0.9;
    }
  }
`;

const GreenDiv = styled.div`
  width: 100%;
  height: 100%;
  background: #3f7652;
  margin: 20px 0 0 0;
  padding: 30px 0;
  color: #ffffff;

  h1 {
    font-size: 55px;
    font-family: "Noto Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 20px 0;
  }

  h2 {
    font-size: 30px;
    font-weight: 500;
    font-family: "Noto Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 20px 0;
  }

  h3 {
    font-size: 30px;
    font-weight: 400;
    font-family: "Noto Sans", sans-serif;
    letter-spacing: 0.01em;
    padding: 5px 0;
  }
  h4 {
    font-size: 22px;
    font-weight: 400;
    font-family: "Noto Sans", sans-serif;
    padding: 0;
    margin: 0;
  }

  h5 {
    font-size: 22px;
    font-weight: 400;
    font-family: "Noto Sans", sans-serif;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 45px;
      padding: 20px 0;
    }

    h2 {
      font-size: 25px;
      font-weight: 500;
      padding: 20px 0;
    }

    h3 {
      font-size: 25px;
      font-weight: 400;
      padding: 5px 0;
    }
    h4 {
      font-size: 16px;
      font-weight: 400;
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const ContactContainer = styled.div`
  width: 50%;
  height: 100%;
  margin: auto;

  @media (max-width: 1024px) {
    width: 60%;
  }

  @media (max-width: 500px) {
    width: 80%;
  }
`;
const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #dbdbdb;
`;
