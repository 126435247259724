import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../Components/Footer";
import {
  AppleStore,
  DownloadBack,
  GooglePlay,
  iBeorLogo,
  iphonePoster,
} from "../Utils/Images";

export default function Download() {
  const navigate = useNavigate();
  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <DownloadWrapper>
        <DownloadContainer>
          <LeftSection>
            <div className="upPart">
              <img
                src={iBeorLogo}
                alt="iBeorLogo"
                onClick={() => navigate("/")}
              />

              <h1>
                Find Singles near <br />
                you Today
              </h1>
            </div>

            <div className="MobileImage">
              <img src={iphonePoster} alt="Iphone" />
            </div>

            <div className="downPart">
              <a href="https://apps.apple.com/us/app/ibeor-date-africans-blacks/id1610779843">
                <img src={AppleStore} alt="Apple Store" />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.ibeor&hl=en_IN&gl=US">
                <img src={GooglePlay} alt="Google Play" />
              </a>
            </div>
          </LeftSection>
          <RightSection>
            <img src={iphonePoster} alt="Iphone" />
          </RightSection>
        </DownloadContainer>
      </DownloadWrapper>
      <SupportedPlatform>
        <DownloadContainer style={{ flexDirection: "column" }}>
          <h1>Supported Platforms and Devices</h1>
          <h4>
            iBeor is currently available on iOS, and Android or get more
            information on ibeor.com
            <br /> You can also visit the{" "}
            <a href="https://apps.apple.com/in/app/" target="_blank">
              {" "}
              Apple Store
            </a>{" "}
            and search ibeor or{" "}
            <a href="https://play.google.com/store/" target="_blank">
              Google Play store
            </a>{" "}
            .<br /> iBeor currently supports iOS 15.4 and up, Android 7.0 and
            up, and the latest versions of all.
          </h4>
        </DownloadContainer>
      </SupportedPlatform>
      <div className="white-space" />

      {size >= 500 && <Footer />}
    </div>
  );
}

const SupportedPlatform = styled.div`
  width: 100%;
  height: 100%;
  background: #3f7652;
  color: #fff;

  h1 {
    font-size: 56px;
    margin: 35px 0;
    font-family: "Noto Sans", sans-serif;
  }

  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    margin: 10px 0 140px 0;
    font-family: "Noto Sans", sans-serif;
    padding: 20px 0;
  }

  a {
    color: #fff;
  }

  @media (max-width: 1420px) {
    h1 {
      font-size: 40px;
    }

    h4 {
      font-size: 18px;
    }
  }

  @media (max-width: 878px) {
    h1 {
      font-size: 30px;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const RightSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  img {
    width: 410px;
    padding: 10px;
    margin: 60px 0 0 0;
  }

  @media (max-width: 1420px) {
    height: 100%;
    img {
      width: 80%;
      padding: 10px;
      margin: 60px 0 0 0;
    }
  }

  @media (max-width: 873px) {
    img {
      width: 100%;
      padding: 10px;
      margin: 60px 0 0 0;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
const LeftSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;

  .upPart {
    margin: 0 0 220px 0;

    img {
      width: 430px;
      padding: 10px;
      cursor: pointer;
    }

    h1 {
      font-size: 45px;
      margin: 35px 20px;
      font-family: "Noto Sans", sans-serif;
      letter-spacing: 0.01em;
      padding: 0 30px;
    }
  }

  .downPart {
    img {
      width: 190px;
      margin: 0 20px;
    }
  }

  .MobileImage {
    img {
      display: none;
    }
  }

  @media (max-width: 1420px) {
    .upPart {
      margin: 0;
      img {
        width: 70%;
        padding: 10px;
        cursor: pointer;
      }

      h1 {
        font-size: 35px;
        margin: 10px;
        font-family: "Noto Sans", sans-serif;
        letter-spacing: 0.01em;
        padding: 0 30px;
      }
    }

    .downPart {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: end;

      img {
        width: 40%;
        margin: 0 20px;
      }
    }
  }

  @media (max-width: 873px) {
    .upPart {
      h1 {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 600px) {
    .upPart {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      img {
        width: 70%;
        padding: 10px;
        cursor: pointer;
      }

      h1 {
        font-size: 18px;
      }
    }

    .downPart {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0;

      img {
        width: 100px;
        margin: 0 20px;
      }
    }

    .MobileImage {
      img {
        display: block;
        width: 40%;
        margin: auto;
      }
    }
  }
`;

const DownloadContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  @media (max-width: 500px) {
    width: 80%;
  }
`;

const DownloadWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: url(${DownloadBack});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 50px 0 0 0;
`;
