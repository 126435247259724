import React from "react";
import styled from "styled-components";
import Header from "../Components/Header";
import { Container } from "../GobalStyle";

import Slider from "react-slick";
import { SampleNextArrow, SamplePrevArrow } from "./Home";
// import mainslider1 from "../Images/mainslider1.webp";
import Footer from "../Components/Footer";
import {
  AdvertiseGridImg,
  AdvertiseMiddle,
  AdvertiseUpper,
  mainslider1,
} from "../Utils/Images";

const Advertise = () => {
  const slidersettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    pauseOnHover: false,
  };
  return (
    <AdvertiseWrapper>
      <Container>
        <Header />
      </Container>

      <div className="advertise-content">
        <div className="advertise-text">
          <div className="upper-content">
            <h2>
              <b>Grow Your Business With iBeor</b>
            </h2>
            <p>
              Reach new customers online with iBeor Ads. Be seen where your
              customers are searching, browsing and watching across iBeor Dating
              Platforms, Apple, and Android Devices. Get the most of your
              marketing budget with automated solutions from iBeor Ads. iBeor
              has over 1.5 Million active users and counting.
            </p>
            <img alt="" src={AdvertiseUpper} />
          </div>
          <div className="lower-content">
            <h2>5000+ HAPPY CUSTOMERS</h2>
            <p>
              <b>Our Rate depends on How Much you Want to Spend</b>
            </p>
          </div>
          <div className="getStartedBtn">
            <button>Get Started</button>
          </div>
        </div>
        <div className="advertiseUpperImg">
          <img alt="" src={AdvertiseUpper} />
        </div>
      </div>
      <div className="advertise-ads">
        <h1>
          <b>This is how your Ads will look on iBeor Dating Apps</b>
        </h1>
      </div>
      <div className="advertise-middle-img">
        <img alt="" src={AdvertiseMiddle} />
      </div>
      <div className="advertise-grid-system">
        <div className="grid-content">
          <h2>Why Advertise on iBeor?</h2>

          <div className="Link-points">
            <span>
              <li></li>
            </span>
            <p>
              <b>Target all Ethnicities : </b>On iBeor we have all races and
              ethnicities. On iBeor - African Dating App you can target all
              Blacks & Africans if your Business is to target Black People. The
              same as iBeor Official Dating App you can target all nationalities
              or ethnic group of race - people you want to show your ADs to.{" "}
            </p>
          </div>
          <div className="Link-points">
            <span>
              <li></li>
            </span>
            <p>
              <b>Target all Ethnicities : </b>On iBeor we have all races and
              ethnicities. On iBeor - African Dating App you can target all
              Blacks & Africans if your Business is to target Black People. The
              same as iBeor Official Dating App you can target all nationalities
              or ethnic group of race - people you want to show your ADs to.{" "}
            </p>
          </div>
          <div className="Link-points">
            <span>
              <li></li>
            </span>
            <p>
              <b>Target all Ethnicities : </b>On iBeor we have all races and
              ethnicities. On iBeor - African Dating App you can target all
              Blacks & Africans if your Business is to target Black People. The
              same as iBeor Official Dating App you can target all nationalities
              or ethnic group of race - people you want to show your ADs to.{" "}
            </p>
          </div>
        </div>
        <div className="grid-Image">
          <img alt="" src={AdvertiseGridImg} />
        </div>
      </div>

      <Slider {...slidersettings}>
        <Imgslider>
          <img src={mainslider1} alt="slider1" />
        </Imgslider>
        <Imgslider>
          <img src={mainslider1} alt="slider1" />
        </Imgslider>
      </Slider>
      <Footer />
    </AdvertiseWrapper>
  );
};
export default Advertise;

const AdvertiseWrapper = styled.div`
  border-top: 2px solid #a0138e;
  height: 100%;
  width: 100%;

  .advertise-content {
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, #d1968e 50%, #884c43 100%);
    display: flex;
  }
  .advertise-text {
    width: 50%;
    padding-top: 3rem;
    padding-left: 22%;
    @media (max-width: 1050px) {
      width: 100%;
      padding-left: 5%;
    }
    @media (max-width: 1550px) {
      padding-left: 10%;
    }
  }
  .upper-content {
    p {
      width: 75%;
    }
    img {
      display: none;
    }
    @media (max-width: 1050px) {
      p {
        width: 95%;
      }
      img {
        display: block;
        height: 550px;
        width: 400px;
      }
    }
    @media (max-width: 450px) {
      img {
        width: 92%;
        height: 450px !important;
        display: block;
      }
    }
    @media (max-width: 650px) {
      img {
        width: 92%;
        height: 450px;
        margin: auto;
        margin-right: 10%;
      }
    }
    @media (max-width: 1050px) {
      img {
        width: 92%;
        height: 650px;
        margin: auto;
        margin-right: 10%;
      }
    }
  }
  .lower-content {
    h2 {
      color: #ffff;
    }
    @media (max-width: 450px) {
      h2 {
        margin-right: 5%;
        text-align: center;
      }
      p {
        margin-right: 5%;
      }
    }
    @media (max-width: 650px) {
      h2 {
        margin-right: 5%;
        text-align: center;
      }
      p {
        margin-right: 5%;
        text-align: center;
      }
    }
    @media (max-width: 1050px) {
      h2 {
        margin-right: 5%;
        text-align: center;
      }
      p {
        margin-right: 5%;
        text-align: center;
      }
    }
  }
  .advertiseUpperImg {
    width: 50%;
    height: 100%;
    img {
      position: absoulte;
      top: 0;
      left: 10;
      height: 750px;
      width: 500px;
    }
    @media (max-width: 1050px) {
      width: 0%;
      img {
        display: none;
      }
    }
  }
  .getStartedBtn {
    margin-top: 30px;
    button {
      padding: 10px 35px;
      font-size: 25px;
      color: #c74300;
      border: 1px solid #c74300;
      border-radius: 40px;
    }
    button:hover {
      background-color: #c74300;
      color: #fff;
      border-radius: 20px;
    }
    @media (max-width: 450px) {
      justify-content: center;
      margin-right: 5%;
      display: flex;
      margin-top: 0px;
      button {
        padding: 10px 20px;
        font-size: 17px;
        margin-bottom: 20px;
      }
    }
    @media (max-width: 1050px) {
      justify-content: center;
      margin-right: 5%;
      display: flex;
      margin-top: 0px;
    }
  }
  .advertise-ads {
    width: 100%;
    background: #d09087;
    padding: 10px;
    display: flex;
    justify-content: center;
    h1 {
      text-align: center;
    }
    @media (max-width: 500px) {
      display: flex;
      justify-content: center;
      h1 {
        font-size: 20px;
        text-align: left;
      }
    }
    @media (max-width: 1050px) {
      display: flex;
      justify-content: center;
      h1 {
        font-size: 25px;
        text-align: left;
      }
    }
  }
  .advertise-middle-img {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .advertise-grid-system {
    width: 100%;
    display: flex;
    .grid-content {
      width: 50%;
      text-align: center;
      h2 {
        color: #c74300;
        margin-bottom: 40px;
      }
    }
    .grid-Image {
      width: 50%;
      img {
        width: 100%;
      }
    }
    @media (max-width: 800px) {
      display: block;
      .grid-content {
        width: 100%;
      }
      .grid-Image {
        width: 100%;
      }
    }
    @media (max-width: 1600px) {
      .grid-Image img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .Link-points {
    display: flex;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    text-align: left;
    span {
      display: flex;
    }
    .Link-headings {
      white-space: nowrap;
    }
    @media (max-width: 750px) {
      width: 90%;
    }
    @media(max-width: 1500px){
      width: 76%;
    }

  }
`;
const Imgslider = styled.div`
  img {
    width: 100%;
  }
`;
