import React from "react";
import { useLocation } from "react-router-dom";
import styledComponents from "styled-components";
import { iBeorLogo } from "../Utils/Images";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Headermain>
      <div>
        <HeaderWrapper>
          <a href="/">
            <img src={iBeorLogo} alt="iBeorLogo" />
          </a>
        </HeaderWrapper>
      </div>
      {location.pathname === "/" && (
        <div>
          <Headercontent>
            <ul>
              <li onClick={() => navigate("/ibeor-africa")}>iBeor Africa</li>
              <li onClick={() => navigate("/ibeor-advertising-form")}>$ Affiliate</li>
              <li onClick={() => navigate("/ibeor-advertise")}>Advertise with iBeor</li>
              <li onClick={() => navigate("/download")}>Download </li>
            </ul>
          </Headercontent>
        </div>
      )}
    </Headermain>
  );
}

const HeaderWrapper = styledComponents.div`
width:100%;
img {
width: auto;
height:82px;
min-height:82px;
padding:8px;
}
@media (max-width: 1400px) {
  img {
    width: auto;
    height:55px;
    min-height: 55px;
    }
}
`;
const Headercontent = styledComponents.div`
width: 100%;
display: flex;

ul{
  list-style:none;
  font-style:none;
  display:flex;
  margin-top:11px;
  cursor: pointer;
}
li{
  border-Right: 2px solid black;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: playfairdisplay-bold, "playfair display", serif;
  padding: 0 8px;
  
}
li:hover{
  color: #cc2643;
}
@media(max-width:820px){
 ul{
  list-style:none;
  padding-left:0rem;
 }
  li{
    font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: playfairdisplay-bold, "playfair display", serif;
  }
}
@media(min-width:420px) and (max-width:620px){
  display: flex;
  justify-content: center;
  ul{
   list-style:none;
   padding-left:0rem;
  }
   li{
     font-style: normal;
   font-weight: 700;
   font-size: 13.5px;
   line-height: 28px;
   font-family: playfairdisplay-bold, "playfair display", serif;
   }
 }
 @media(min-width:320px) and (max-width:420px){
  width: 100%;
  display: flex;
  justify-content: center;
  ul{
   list-style:none;
   padding-left:0rem;
  }
   li{
     font-style: normal;
   font-weight: 700;
   font-size: 11px;
   line-height: 28px;
   font-family: playfairdisplay-bold, "playfair display", serif;
   }
 }
`;
const Headermain = styledComponents.div`
display:flex;
justify-content:space-between;
align-items:center;
align-content:center;
@media(max-width:820px){
flex-wrap:wrap;
}
`;
