import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "../Components/ScrollTop";
import CommunityGuidelines from "../Pages/CommunityGuidelines";
import ContactUs from "../Pages/ContactUs";
import Download from "../Pages/Download";
import FAQS from "../Pages/FAQS";
import Home from "../Pages/Home";
import Partnership from "../Pages/Partnership";
import Policy from "../Pages/Policy";
import Privacy from "../Pages/Privacy";
import Safety from "../Pages/Safety";
import SafetyTips from "../Pages/SafetyTips";
import Security from "../Pages/Security";
import TermCondition from "../Pages/TermCondition";
import Africa from "../Pages/Africa";
import Advertise from "../Pages/Advertise";
import Advertisingform from "../Pages/Advertisingform";

export default function AllRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ibeor-africa" element={<Africa />} />
          <Route path="/ibeor-advertise" element={<Advertise />} />
          <Route path="/ibeor-advertising-form" element={<Advertisingform />} />
          <Route path="/download" element={<Download />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TermCondition />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/security" element={<Security />} />
          <Route path="/partnership" element={<Partnership />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/safety" element={<Safety />} />
          <Route path="/safetytips" element={<SafetyTips />} />
          <Route path="/faq" element={<FAQS />} />
          <Route path="/resetPhoneNumber/:token" element={<Home />} />
          <Route
            path="/communityguidelines"
            element={<CommunityGuidelines />}
          />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
