import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../Components/Footer";
import { FooterContainer, HeaderLogoWrap, SingleLine } from "../GobalStyle";
import { iBeorLogo } from "../Utils/Images";

export default function SafetyTips() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        <HeaderLogoWrap>
          <img src={iBeorLogo} alt="LOGO" onClick={() => navigate("/")} />
        </HeaderLogoWrap>
        <SafetyTipsWrapper>
          <header>DATING SAFETY TIPS</header>
          <p>
            Meeting new people is exciting, but you should always be cautious
            when interacting with someone you don’t know. Use your best judgment
            and put your safety first, whether you are exchanging initial
            messages or meeting in person. While you can’t control the actions
            of others, there are things you can do to help you stay safe during
            your iBeor experience.
          </p>
          <h4>Online Safety</h4>
          <li>Never Send Money or Share Financial Information</li>
          <p>
            Never send money, especially over wire transfer, even if the person
            claims to be in an emergency. Wiring money is like sending cash —
            it’s nearly impossible to reverse the transaction or trace where the
            money went. Never share information that could be used to access
            your financial accounts. If another user asks you for money, report
            it to us immediately. For tips on avoiding romance scams, check out
            some advice from the U.S Federal Trade Commission
            <a
              href="https://consumer.ftc.gov/articles/what-you-need-know-about-romance-scams"
              target="_blank"
            >
              {" "}
              on the FTC website.
            </a>
          </p>
          <li>Protect Your Personal Information</li>
          <p>
            Never share personal information, such as your social security
            number, home or work address, or details about your daily routine
            (e.g., that you go to a certain gym every Monday) with people you
            don’t know. If you are a parent, limit the information that you
            share about your children on your profile and in early
            communications. Avoid sharing details such as your children’s names,
            where they go to school, or their ages or genders.
          </p>

          <li>Stay on the Platform</li>
          <p>
            Keep conversations on the IBeor platform while you’re getting to
            know someone. Because exchanges on IBeor are subject to our Safe
            Message Filters (learn more here), users with bad intentions often
            try to move the conversation to text, messaging apps, email, or
            phone right away.
          </p>
          <li>Be Wary of Long Distance and Overseas Relationships</li>
          <p>
            Watch out for scammers who claim to be from your country but stuck
            somewhere else, especially if they ask for financial help to return
            home. Be wary of anyone who will not meet in person or talk on a
            phone/video call—they may not be who they say they are. If someone
            is avoiding your questions or pushing for a serious relationship
            without meeting or getting to know you first — that’s a red flag.
          </p>
          <li>Report All Suspicious and Offensive Behavior</li>
          <p>
            You know when someone’s crossed the line and when they do, we want
            to know about it. Block and report anyone that violates our terms.
            Here are some examples of violations:
          </p>
        </SafetyTipsWrapper>

        <ListPoint>Requests for money or donations</ListPoint>
        <ListPoint>Underage users</ListPoint>
        <ListPoint>Harassment, threats, and offensive messages</ListPoint>
        <ListPoint>
          Inappropriate or harmful behavior during or after meeting in person
        </ListPoint>
        <ListPoint>Fraudulent profiles</ListPoint>
        <ListPoint>
          Spam or solicitation including links to commercial websites or
          attempts to sell products or services You can report any concerns
          about suspicious behavior from any profile page or messaging window
          here. For more information, check out our Community Guidelines.
        </ListPoint>

        <SafetyTipsWrapper>
          <li>Protect Your Account</li>
          <p>
            Be sure to pick a strong password, and always be careful when
            logging into your account from a public or shared computer. iBeor
            will never send you an email asking for your username and password
            information — if you receive an email asking for account
            information, report it immediately.
          </p>

          <h4>Meeting in Person</h4>
          <li>Don’t Be In A Rush</li>
          <p>
            Take your time and get to know the other person before agreeing to
            meet or chat off IBeor. Don’t be afraid to ask questions to screen
            for any red flags or personal dealbreakers. A phone or video call
            can be a useful screening tool before meeting.
          </p>

          <li>Meet in Public and Stay in Public</li>

          <p>
            Meet for the first few times in a populated, public place — never at
            your home, your date’s home, or any other private location. If your
            date pressures you to go to a private location, end the date.
          </p>

          <li>Tell Friends and Family About Your Plans</li>
          <p>
            Tell a friend or family member of your plans, including when and
            where you’re going. Have your cell phone charged and with you at all
            times.
          </p>

          <li>Be in Control of Your Transportation</li>
          <p>
            We want you to be in control of how you get to and from your date so
            that you can leave whenever you want. If you’re driving yourself,
            it’s a good idea to have a backup plan such as a ride-share app or a
            friend to pick you up.
          </p>
          <li>Know Your Limits</li>
          <p>
            Be aware of the effects of drugs or alcohol on you specifically —
            they can impair your judgment and your alertness. If your date tries
            to pressure you to use drugs or drink more than you’re comfortable
            with, hold your ground and end the date.
          </p>
          <li>Don’t Leave Drinks or Personal Items Unattended</li>

          <p>
            Know where your drink comes from and know where it is at all times —
            only accept drinks poured or served directly from the bartender or
            server. Many substances that are slipped into drinks to facilitate
            sexual assault are odorless, colorless, and tasteless. Also, keep
            your phone, purse, wallet, and anything containing personal
            information on you at all times.
          </p>

          <li>If You Feel Uncomfortable, Leave</li>
          <p>
            It’s okay to end the date early if you’re feeling uncomfortable. In
            fact, it’s encouraged. And if your instincts are telling you
            something is off or you feel unsafe, ask the bartender or server for
            help.
          </p>

          <li>LGBTQ+ Travel</li>
          <p>
            Be careful while traveling
            <br />
            We recognize and believe in the importance of being inclusive of all
            gender identities and sexual orientations, but the reality is this:
            nowhere in the world is without potential risk, and some countries
            have specific laws that target LGBTQ+ people.
            <br />
            Check out the laws around you when you travel to a new place and
            research what types of legal protection, if any, are available to
            you based on sexual orientation. In the event that you’re in unsafe
            territory, we suggest toggling off “Show me on IBeor” which you can
            find under the settings page.
            <br />
            If you have added a sexual orientation to your profile and choose to
            be shown on IBeor, we will hide your sexual orientation from your
            profile until you leave that area.
            <br />
            It’s important to exercise extra caution if you choose to connect
            with new people in these countries - as some law enforcement have
            been known to use dating apps as tools for potential entrapment.
            Some countries have also recently introduced laws that criminalize
            communications between individuals on same-sex dating applications
            or websites and even aggravate penalties if that communication leads
            to sexual encounters.
            <br />
            Visit{" "}
            <a
              href="https://ilga.org/maps-sexual-orientation-laws"
              target="_blank"
            >
              ILGA World
            </a>{" "}
            to see the latest sexual orientation laws by country, and consider
            donating to support their research.
            <br />
            Source: ILGA World, Updated March 2019
          </p>

          <h4>Sexual Health & Consent</h4>
          <li>Protect Yourself</li>
          <p>
            When used correctly and consistently, condoms can significantly
            reduce the risk of contracting and passing on STI’s like HIV. But,
            be aware of STIs like herpes or HPV that can be passed on through
            skin-to-skin contact. The risk of contracting some STIs can be
            reduced through{" "}
            <a
              href="https://www.ashasexualhealth.org/vaccines/"
              target="_blank"
            >
              vaccination.
            </a>
          </p>
          <li>Know Your Status</li>

          <p>
            Not all STIs show symptoms, and you don’t want to be in the dark
            about your status. Stay on top of your health and prevent the spread
            of STIs by getting tested regularly. Here’s where you can{" "}
            <a href="https://gettested.cdc.gov/" target="_blank">
              {" "}
              find a clinic near you{" "}
            </a>{" "}
            (US only).
          </p>

          <li>Talk About It</li>

          <p>
            Communication is everything: Before you get physically intimate with
            a partner, talk about sexual health and STI testing. And be aware —
            in some places, it’s actually a crime to knowingly pass on an STI.
            Need help starting the conversation?
            <a
              href="https://www.plannedparenthood.org/learn/stds-hiv-safer-sex/get-tested/how-do-i-talk-my-partner-about-std-testing"
              target="_blank"
            >
              {" "}
              Here are some tips.
            </a>
          </p>

          <li>Consent</li>
          <p>
            All sexual activity must start with consent and should include
            ongoing check-ins with your partner. Verbal communication can help
            you and your partner ensure that you respect each other’s
            boundaries. Consent can be withdrawn at any time, and sex is never
            owed to anyone. Do not proceed if your partner seems uncomfortable
            or unsure, or if your partner is unable to consent due to the
            effects of drugs or alcohol. Read more about it{" "}
            <a
              href="https://www.rainn.org/articles/what-is-consent"
              target="_blank"
            >
              here.
            </a>
          </p>
          <h4>Resources for Help, Support, or Advice</h4>

          <p>
            Remember — even if you follow these tips, no method of risk
            reduction is perfect. If you have a negative experience, please know
            that it is not your fault and help is available. Report any
            incidents iBeor, and consider reaching out to one of the resources
            below. If you feel you are in immediate danger or need emergency
            assistance, call 911 (U.S. or Canada) or your local law enforcement
            agency.
          </p>
          <h6>RAINN’s National Sexual Assault Hotline</h6>
          <h6>
            1-800-656-HOPE (4673) |{" "}
            <a href="online.rainn.org" target="_blank">
              online.rainn.org
            </a>{" "}
            |{" "}
            <a href="www.rainn.org" target="_blank">
              www.rainn.org
            </a>{" "}
          </h6>
          <h6>Planned Parenthood</h6>
          <h6>
            1-800-230-7526 |{" "}
            <a href="www.plannedparenthood.org" target="_blank">
              www.plannedparenthood.org
            </a>{" "}
            ​
          </h6>
          <h6>National Domestic Violence Hotline</h6>
          <h6>
            1-800-799-SAFE (7233) or 1-800-787-3224 |{" "}
            <a href="www.thehotline.org" target="_blank">
              www.thehotline.org
            </a>{" "}
          </h6>
          <h6>National Human Trafficking Hotline</h6>
          <h6>
            1-888-373-7888 or text 233733 |{" "}
            <a href="www.humantraffickinghotline.org" target="_blank">
              www.humantraffickinghotline.org
            </a>
          </h6>
          <h6>National Sexual Violence Resource Center</h6>
          <h6>
            1-877-739-3895 |{" "}
            <a href=" www.nsvrc.org" target="_blank">
              {" "}
              www.nsvrc.org
            </a>{" "}
            ​
          </h6>
          <h6>National Center for Missing & Exploited Children</h6>
          <h6>
            1-800-THE-LOST (843-5678) |{" "}
            <a href="www.cybertipline.com" target="_blank">
              www.cybertipline.com
            </a>{" "}
          </h6>
          <h6>Cyber Civil Rights Initiative</h6>
          <h6>
            1-844-878-2274 |{" "}
            <a href="www.cybercivilrights.org" target="_blank">
              www.cybercivilrights.org
            </a>{" "}
            ​
          </h6>
          <h6>Victim Connect - Crime Victim Resource Center</h6>
          <h6>
            1-855-4VICTIM (855-484-2846) |{" "}
            <a href="www.victimconnect.org" target="_blank">
              www.victimconnect.org
            </a>{" "}
          </h6>
          <h6>FBI Internet Crime Complaint Center</h6>
          <h6>
            {" "}
            <a href="www.ic3.gov" target="_blank">
              www.ic3.gov
            </a>{" "}
            ​
          </h6>
          <h6>LGBT National Help Center</h6>
          <h6>
            1-888-843-4564 |{" "}
            <a href="www.glbtnationalhelpcenter.org" target="_blank">
              www.glbtnationalhelpcenter.org
            </a>{" "}
          </h6>
          <h6>Trans Lifeline</h6>
          <h6>
            1-877-565-8860 (US) or 1-877-330-6366 (CA) |{" "}
            <a href="www.translifeline.org" target="_blank">
              www.translifeline.org
            </a>
          </h6>

        </SafetyTipsWrapper>
  
      </FooterContainer>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const ListPoint = styled.li`
  font-family: "playfair display", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  margin: 5px 0;
`;

const SafetyTipsWrapper = styled.div`
  header {
    text-align: center;
    font-family: "playfair display", sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2px;
    color: #292929;
    margin: 20px 0;
  }

  a {
    font-family: "playfair display", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 20px 30px 20px 0;
    color: #000;
    margin: 20px 0;
  }

  p {
    font-family: "playfair display", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    margin: 20px 30px 20px 0;
    color: #000;
    margin: 20px 0;
  }

  h4 {
    font-family: "playfair display", sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    margin: 20px 30px 20px 0;
    color: #000;
    margin: 20px 0;
    text-align: center;
  }

  li {
    font-family: "playfair display", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    display: inline-block;
    margin: 20px 30px 20px 0;
    color: #000;
    margin: 10px 0;

    // &::before {
    //   content: "";
    //   width: 10px;
    //   height: 10px;
    //   background: red;
    // }
  }

  h6 {
    font-family: "playfair display", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 20px 30px 20px 0;
    color: #000;
    margin: 20px 0;
    text-align: center;
  }
`;
