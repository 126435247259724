import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";
import { FooterContainer, HeaderLogoWrap, SingleLine } from "../GobalStyle";
import { iBeorLogo } from "../Utils/Images";

export default function Policy() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <FooterContainer>
        <HeaderLogoWrap>
          <img src={iBeorLogo} alt="LOGO" onClick={() => navigate("/")} />
        </HeaderLogoWrap>

        <CookiePolicyWrap>
          <heading>Cookie Policy</heading>
          <p>
            Introduction
            <br />
            IBEOR is committed to protecting your privacy. We aim to provide
            trustworthy, industry-leading products and services so that you can
            focus on building meaningful connections. Our approach to privacy is
            to provide you with clear information about our data practices.
            That’s why we've tried to keep legal and technical jargon to a
            minimum.
            <br />
            This Cookie Policy explains what cookies are, what types of cookies
            are placed on your device when you visit our website and how we use
            them.
            <br />
            This Cookie Policy does not address how we deal with your personal
            information generally. To learn more about how we process your
            personal information, please see our Privacy Policy here. What are
            cookies?
            <br />
            Cookies are small text files that are sent to or accessed from your
            web browser or your device's memory. A cookie typically contains the
            name of the domain (internet location) from which the cookie
            originated, the "lifetime" of the cookie (i.e., when it expires) and
            a randomly generated unique number or similar identifier. A cookie
            also may contain information about your device, such as user
            settings, browsing history and activities conducted while using our
            services. Are there different types of cookies?
            <br />
            First-party and third-party cookies
            <br />
            There are first-party cookies and third-party cookies. First-party
            cookies are placed on your device directly by us. For example, we
            use first-party cookies to adapt our website to your browser's
            language preferences and to better understand your use of our
            website. Third-party cookies are placed on your device by our
            partners and service providers. For example, we use third-party
            cookies to measure user numbers on our website or to enable you to
            share content with others across social media platforms.
            <br />
            Session and persistent cookies
            <br />
            There are session cookies and persistent cookies. Session cookies
            only last until you close your browser. We use session cookies for a
            variety of reasons, including to learn more about your use of our
            website during one single browser session and to help you to use our
            website more efficiently. Persistent cookies have a longer lifespan
            and aren't automatically deleted when you close your browser. These
            types of cookies are primarily used to help you quickly sign-in to
            our website again and for analytical purposes.
            <br />
            What about other tracking technologies, like web beacons?
            <br />
            Other technologies such as web beacons (also calls pixel tags or
            clear gifs), tracking URLs or software development kits (SDKs) are
            used for similar purposes. Web beacons are tiny graphics files that
            contain a unique identifier that enable us to recognize when someone
            has visited our service or opened an e-mail that we have sent them.
            Tracking URLs are custom generated links that help us understand
            where the traffic to our webpages comes from. SDKs are small pieces
            of code included in apps, which function like cookies and web
            beacons.
            <br />
            For simplicity, we also refer to these technologies as "cookies" in
            this Cookie Policy. What do we use cookies for?
            <br />
            Like most providers of online services, we use cookies to provide,
            secure and improve our services, including by remembering your
            preferences, recognizing you when you visit our website and
            personalizing and tailoring ads to your interests. To accomplish
            these purposes, we also may link information from cookies with other
            personal information we hold about you.
            <br />
          </p>

          <h4>How can you control cookies?</h4>

          <p>
            There are several cookie management options available to you. Please
            note that changes you make to your cookie preferences may make
            browsing our website a less satisfying experience. In some cases,
            you may even find yourself unable to use all or part of our site.
            Browser and devices controls
            <br />
            Some web browsers provide settings that allow you to control or
            reject cookies or to alert you when a cookie is placed on your
            computer. The procedure for managing cookies is slightly different
            for each internet browser. You can check the specific steps in your
            particular browser help menu.
            <br />
            You also may be able to reset device identifiers by activating the
            appropriate setting on your mobile device. The procedure for
            managing device identifiers is slightly different for each device.
            You can check the specific steps in the help or settings menu of
            your particular device.
            <br />
            Interest-based advertising tools
            <br />
            You can opt out of seeing online interest-based advertising from
            participating companies through the Digital Advertising Alliance,
            the Interactive Digital Advertising Alliance or Appchoices (apps
            only).
            <br />
            Opting out does not mean you will not see advertising - it means you
            won't see personalized advertising from the companies that
            participate in the opt-out programs. Also, if you delete cookies on
            your device after you opted out, you will need to opt-out again.
            <br />
            Social Cookies
            <br />
            To allow you to share content on social media, some features of this
            website use social media plug-ins (e.g., Twitter™ "Share to Twitter"
            or LinkedIn™ "in" buttons). Depending on your social media account
            settings, we automatically receive information from the social media
            platform when you use the corresponding button on our website.
          </p>

          <p>
            To learn more about social media cookies, we suggest you refer to
            your social media platform's cookie policy and privacy policy.
            <br />
            Google™ Cookies
            <br />
            Google Analytics
            <br />
            We use Google Analytics, which is a Google service that uses cookies
            and other data collection technologies to collect information about
            your use of the website and services in order to report website
            trends.
            <br />
            You can opt out of Google Analytics by visiting
            www.google.com/settings/ads or by downloading the Google Analytics
            opt-out browser add-on at https://tools.google.com/dlpage/gaoptout.
            <br />
            How to contact us?
            <br />
            If you have questions about this Cookie Policy, here's how you can
            reach us:
          </p>

          <p>
            Online: here.
            <br />
            <br />
            By Post:
            <br />
            <br />
            Privacy Officer
            <br />
            iBeor, Inc
            <br />
            5429 Lyndon B Johnson Fwy
            <br />
            Dallas, TX 75240, USA
          </p>
        </CookiePolicyWrap>
      </FooterContainer>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const CookiePolicyWrap = styledComponents.div`

heading {
 

  display: flex;
  flex-direction: column;
  align-items: center;
    font-family: 'playfair display',sans-serif;
      list-style: none;
      font-size: 52px;
      font-weight: 700;
      margin: 0 0 20px 0;
      color:#8c331c;


}

p{
  font-family: 'playfair display',sans-serif;
  list-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin: 10px 0 ;
  color:#000;

}

h4 {
  font-family: 'playfair display',sans-serif;
  list-style: normal;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  margin: 10px 0 ;
  color:#000;
}


`;
