import React from "react";
import styled from "styled-components";
import Header from "../Components/Header";
import CashPromoting from "../Assets/CashPromoting.png";
import FormLowerImage from "../Assets/FormLowerImage.png";
import Footer from "../Components/Footer";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { LoaderWrapper } from "../GobalStyle";
import { Spinner } from "react-bootstrap";

const Advertisingform = () => {
  const [ambassadorName, setAmbassadorName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tikTok, setTikTok] = useState("");
  const [youTube, setYouTube] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false)


  const AmbassadorForm = (value) => {
    value.preventDefault();

    if (instagram || tikTok || youTube) {
      setLoading(true)
      let req = {
        ambassadorName: ambassadorName,
        companyName: companyName,
        instagram: instagram,
        tiktok: tikTok,
        youtube: youTube,
        tellUsMore: description,
      }

      axios
        .post(process.env.REACT_APP_BRAND_AMBASSADOR, req).then((res) => {
          if (res.status === 200) {
            setLoading(false)
            toast.info(res.message, { theme: "colored" })
          } else {
            setLoading(false)
            toast.error(res.message, { theme: "colored" })
          }

        }).catch((err) => {
          setLoading(false)
          toast.error(err?.response?.data?.message, { theme: "colored" });
        })


    } else {
      toast.error('You have to enter atleast one social media platform', { theme: "colored" })
    }
  };


  if (loading) {
    return (
      <LoaderWrapper>
        <Spinner animation="border" role="status" />
      </LoaderWrapper>
    )
  }
  return (
    <AdvertisingFormWrapper>
      <Header />

      <div className="brand-ambassador">
        <h1>
          <b>Become iBeor Brand Ambassador and Make Money</b>
        </h1>
      </div>

      <div className="Cash-promotingWrapper">
        <div className="Cash-promoting">
          <div className="Cash-promoting-content">
            <h1>Earn Cash Promoting iBeor Dating App</h1>
            <p>
              Earn Cash Promoting iBeor Dating App Interested in Joining iBeor?
              This is a great opportunity to turn your Life around by making
              passive income. Join Now!!!
            </p>
          </div>
          <div className="Cash-promoting-image">
            <div className="Cash-Left">
              <img className="Cash-Left-img" alt="" src={CashPromoting} />
            </div>
            <div className="Cash-Right">
              <img
                className="Cash-Right-img"
                alt=""
                src="https://static.wixstatic.com/media/5f7a31_3080f5e92f3c43fa933b8bf7b84fe499~mv2.gif"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid-content">
        <div className="grid-content-yellow">
          <h3>
            <b>Are you a Social Media Influencer</b>
          </h3>
          <div className="Question-ans">
            <h3>What is a Brand Ambassador?</h3>
            <p>
              A brand ambassador, also known as a corporate ambassador or
              influencer, is a professional who increases awareness of a brand
              by publicly representing the company and its products or services.
              Brand ambassadors act as spokespeople, attempting to influence a
              community to buy a company’s product or service.{" "}
            </p>
          </div>
          <div className="Question-ans">
            <h3>Are you a Brand Ambassador?</h3>
            <p>
              Do you have voice and followers on Social Medias then this program
              is for you. Make money with iBeor by promoting our Brand on your
              platforms. You should have either one of these social medias
              platforms. YouTube, Instagram, Facebook, Twitter, TikTok, and
              Passionate about iBeor mission to spread this dating app.{" "}
            </p>
          </div>
          <div className="Question-ans">
            <h3>The role of the Ambassador</h3>
            <span>
              <li></li>{" "}
              <p>
                Create social media posts (videos & images) that motivate
                friends, family & followers to Download the iBeor Dating App.{" "}
              </p>
            </span>
            <span>
              <li></li>{" "}
              <p>
                Represent and spread iBeor brand on your social media platforms.{" "}
              </p>
            </span>
            <span>
              <li></li>{" "}
              <p>
                Engage with your friends, followers and fans and tell them about
                iBeor by following us also on all social media Platforms like
                Instagram, TikTok, Facebook Page, YouTube, Twitter etc.{" "}
              </p>
            </span>
          </div>
        </div>
        <div className="grid-content-light">
          <h3><b>How does the program work and how do I get paid?</b></h3>

          <div className="Question-ans">
            <h3>HOW DO BRAND AMBASSADORS GET PAID?</h3>

            <ol>
              <li>
                Fill out the form below to express your interest in the program.
                You must have a public Social Media page to be considered with
                lots of views and comments.
              </li>
              <li>
                If accepted into the program, you will receive a unique link to
                track your engagement. The link will be active for a Month and
                every time action is taken you get paid.
              </li>
              <li>
                Ambassadors must create two social media posts to advertise the
                app and tell people to download iBeor App using the unique link.
                At least one of the posts must be a video. Each post must remain
                on your page for at least two weeks.{" "}
              </li>
              <li>
                After a month, we will pay you base on the quality of the
                traffic plus a rate for every click to your unique link.
                (Payment details and rates will be provided via email) PayPal or
                Cashapp payment.{" "}
              </li>
            </ol>

            <div className="Light-Content-Image">
              <img
                className="Light-Content-img"
                alt=""
                src="https://static.wixstatic.com/media/5f7a31_b5420da028b14db4a6df11f23a8d141e~mv2.gif"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="formWrapper">
        <h2>
          Complete the form below and we will get back to you within 24 Hours.
          Brand Ambassador Contract Form
        </h2>

        <div className="form">
          <form onSubmit={AmbassadorForm}>
            <label className="label">
              <i>Ambassador Name *</i>
            </label>{" "}
            <br />
            <input
              className="input"
              type="text"
              name="AmbassadorName"
              value={ambassadorName}
              onChange={(event) => setAmbassadorName(event.target.value)}
              required
            />
            <br />
            <br />
            <br />
            <label className="label">
              <i>Company Name *</i>
            </label>{" "}
            <br />
            <input
              className="input"
              type="text"
              name="CompanyName"
              value={companyName}
              onChange={(event) => setCompanyName(event.target.value)}
              required
            />
            <br />
            <h2>Social Media Platforms</h2>
            <div className="Social-Media-Inputs">
              <span>
                <label className="label">Instagram</label>
                <br />
                <input
                  className="input"
                  type="text"
                  name="Instagram"
                  value={instagram}
                  onChange={(event) => setInstagram(event.target.value)}
                />
              </span>
              <span>
                <label className="label">TikTok</label>
                <input
                  className="input"
                  type="text"
                  name="TikTok"
                  value={tikTok}
                  onChange={(event) => setTikTok(event.target.value)}
                />
              </span>
              <span>
                <label className="label">YouTube</label>
                <input
                  className="input"
                  type="text"
                  name="YouTube"
                  value={youTube}
                  onChange={(event) => setYouTube(event.target.value)}
                />
              </span>
            </div>
            <div className="textareaWrapper">
              <label className="label">
                <i>
                  Tell us more about you and why you fit as a best candidate to
                  Promote iBeor Dating App
                </i>
              </label>

              <textarea
                className="input"
                type="text"
                name="Description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                required
              />
            </div>
            <div className="submitBtnWrapper">
              <input type="submit" value="Submit" />
            </div>
          </form>
        </div>
      </div>

      <div className="formLowerImageWrapper">
        <img style={{ width: "100%" }} alt="" src={FormLowerImage} />
      </div>

      <Footer />
    </AdvertisingFormWrapper>
  );
};
export default Advertisingform;

const AdvertisingFormWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-top: 2px solid #a0138e;
  .brand-ambassador {
    background-color: #fff205;
    width: 100%;
    padding: 15px;
    h1 {
      text-align: center;
    }

    @media (max-width: 480px) {
      h1 {
        text-align: center;
        font-size: 20px;
      }
    }
  }
  .Cash-promotingWrapper {
    width: 100%;
    background-color: #282626;
    color: #e4bfad;

    .Cash-promoting {
      width: 65%;
      margin: auto;

      @media (max-width: 800px) {
        width: 95%;
      }
      @media (max-width: 1500px) {
        width: 70%;
      }
     

      .Cash-promoting-content {
        width: 100%;

        p {
          width: 50%;
          margin-bottom: 0rem;
        }
        @media (max-width: 800px) {
          p {
            width: 90%;
          }
        }
      }
      .Cash-promoting-image {
        width: 100%;
        display: flex;

        .Cash-Left {
          width: 70%;
          .Cash-Left-img {
            width: 90%;
          }
          @media (max-width: 880px) {
            width: 100%;
          }
          @media (max-width: 1994px) {
            width: 100%;
            .Cash-Left-img{
              height: 100%;
            }
          }
        }
        .Cash-Right {
          width: 30%;
          .Cash-Right-img {
            margin-top: 5rem;
            @media(max-width: 2300px){
              margin-top: 8rem;
            }
            @media(max-width: 2360px){
              margin-top: 12.5rem;
            }
            @media(max-width: 2380px){
              margin-top: 13rem;
            }
            @media(max-width: 2250px){
              margin-top: 10rem;
            }
            @media(max-width: 2050px){
              margin-top: 7rem;
            }
            @media(max-width: 2090px){
              margin-top: 7rem;
            }
            @media(max-width: 2190px){
              margin-top: 8.4rem;
            }
           
          }
          @media (max-width: 880px) {
            width: 0%;
            display: none;
          }
        }
      }
    }
  }
  .grid-content {
    display: flex;

    .grid-content-yellow {
      width: 50%;
      padding: 10px 8rem;
      background-color: #feeb05;

      @media(max-width: 1400px){
        padding: 10px 4rem;
      }

      .Question-ans {
        margin-top: 2rem;
        h3 {
          text-align: left;
        }
        span {
          display: flex;
        }
      }
    }
    .grid-content-light {
      width: 50%;
      padding: 10px 8rem;
      background-color: #e4bfad;

      @media(max-width: 1400px){
        padding: 10px 4rem;
      }
      .Question-ans {
        margin-top: 2rem;
        h3 {
          text-align: left;
        }
        span {
          display: flex;
        }
      }
      .Light-Content-Image {
        width: 100%;
        display: flex;
        justify-content: center;
        .Light-Content-img {
          height: 25rem;
        }
      }
    }
    @media (max-width: 800px) {
      display: block;

      .grid-content-yellow {
        width: 100%;
        padding: 10px 20px;
      }

      .grid-content-light {
        width: 100%;
        padding: 0;
      }
    }
  }
  .formWrapper {
    width: 100%;
    background-color: #fafafa;
    margin-bottom: 2rem;
    h2 {
      text-align: center;
      padding: 10px 18rem;
    }

    @media (max-width: 800px) {
      h2 {
        padding: 0;
        font-size: 20px;
      }
    }
    .form {
      margin: auto;
      border: 1px solid #cecece;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      form {
        padding: 4rem 10rem;

        .formLowerImageWrapper {
          width: 100%;
        }

        .label {
          color: #1a2df3;
          font-family: georgia;
          font-weight: 700;
        }

        .input {
          padding: 10px;
          width: 100%;
          border: 1px solid red;
          background-color: #ffecf1;
        }
        h2 {
          text-align: center;
          padding: 0;
          margin-top: 10px;
          font-family: sans-serif;
          font-weight: 700;
        }
        .Social-Media-Inputs {
          display: flex;
          justify-content: space-between;
          width: 100%;
          gap: 20px;

          span {
            text-align: center;
          }
        }
      }
      @media (max-width: 800px) {
        width: 100%;
        form {
          padding: 1rem;
        }
      }
    }
    .textareaWrapper {
      width: 100%;
      margin-top: 3.5rem;

      textarea {
        margin-top: 10px;
        width: 100%;
        height: 140px;
        border-bottom: 1px solid #1a2df3;
        padding: 10px 20px;
        background-color: #ffecf1;
      }
      textarea:focus {
        border-bottom: 5px solid #1a2df3;
      }
    }
    .submitBtnWrapper {
      margin-top: 1rem;
      input {
        background-color: #1a2df3;
        padding: 10px;
        font-size: 20px;
        color: #fff;
        width: 100%;
      }
    }
  }
`;
