import { Field, Form, Formik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import styledComponents from "styled-components";
import { FooterContainer, SingleLine } from "../GobalStyle";
import * as Yup from "yup";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import Footer from "../Components/Footer";

const styleInput = {
  width: "100%",
  height: "40px",
  background: "transparent",
  border: "1px solid black",
};

const styleTextArea = {
  width: "100%",
  height: "90px",
  background: "transparent",
  border: "1px solid black",
};

export default function Partnership() {
  const SignupSchema = Yup.object().shape({
    FirstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    Email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
  });

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);


  const handleSubmit = async (values) => { };
  return (
    <div>
      {/* <FooterContainer>
        <PartnershipWrapper>
          <h1>Partnership with iBeor Today</h1>
          <p >
            This is your Contact section paragraph. Encourage your reader to
            reach out with any questions, comments or to take a different action
            specific to your site. You can also click on the contact form to
            customize the fields.
          </p>
        </PartnershipWrapper>
      </FooterContainer> */}
      {/* <ChatContainer>
        <ChatBox>
          <InnerChatWarpper>
            <h2>Let's Chat</h2>
            <InfoDetails>
              <div>
                <span>Phone</span>
                <label>123-456-789</label>
              </div>
              <div>
                <span>Email</span>
                <label>info@mysite.com</label>
              </div>
              <div>
                <span>Social Media</span>
                <label>
                  <FaFacebookF />
                  <FaTwitter />
                  <FaLinkedinIn />
                  <FaInstagram />
                </label>
              </div>
            </InfoDetails>

            <Formik
              initialValues={{
                // FirstName: "",
                // LastName: "",
                Email: "",
                // Message: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ errors, touched }) => (
                <Form>
                  <InfoDetails style={{ marginTop: "60px" }}>
                    <div>
                      <label>FirstName</label>
                      <Field name="FirstName" style={styleInput} />
                      {errors.FirstName && touched.FirstName ? (
                        <div>{errors.FirstName}</div>
                      ) : null}
                    </div>

                    <div>
                      <label>LastName</label>
                      <Field name="LastName" style={styleInput} />
                      {errors.LastName && touched.LastName ? (
                        <div>{errors.LastName}</div>
                      ) : null}
                    </div>

                    <div>
                      <label>Email * </label>
                      <Field name="Email" style={styleInput} />
                      {errors.Email && touched.Email ? (
                        <div style={{ color: "red", padding: "5px 0 0 0" }}>
                          {errors.Email}
                        </div>
                      ) : null}
                    </div>
                  </InfoDetails>
                  <div style={{ marginTop: "20px" }}>
                    <label>Message</label>
                    <Field name="Message" style={styleTextArea} />
                    {errors.Message && touched.Message ? (
                      <div>{errors.Message}</div>
                    ) : null}
                  </div>

                  <Button type="submit">Send</Button>
                </Form>
              )}
            </Formik>
          </InnerChatWarpper>
        </ChatBox>
      </ChatContainer> */}

      <NewPartnerShipContact>
        <div>
          <h1>iBeor Partnership Program</h1>
          <p>Complete the form below and we will get back to you within 24 Hours.<br />
            Tell us about your business Plans and why you want to join us.</p>
          <div className="PartnershipForm" >
            <section>
              <label>Your Name *</label>
              <input name="name" type="text" />
            </section>


            <section>
              <label>Business Name (optional)</label>
              <input name="name" type="text" />
            </section>



            <section>
              <label>Email</label>
              <input name="name" type="text" />
            </section>



            <section>
              <label>Phone Number</label>
              <input name="name" type="text" />
            </section>

            <section>
              <label>Website (optional)</label>
              <input name="name" type="text" />
            </section>

            <section>
              <label>Tell us why you want to partner with us.</label>
              <textarea name="name" type="text" />
            </section>


            <button>Submit</button>
          </div>
        </div>

      </NewPartnerShipContact>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}


const NewPartnerShipContact = styledComponents.div`

width:100%;
height:100%;
padding: 0 0 150px 0;
background:#feeb05;
div {
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    padding: 0;
    margin-top: 10px;
    font-family: sans-serif;
    font-weight: 700;
  }
  p {
    text-align: center;
    padding: 0;
    font-weight: 500;
    margin-top: 10px;
    font-family: sans-serif;
    font-size:18px;
  }



  .PartnershipForm {
    width:80%;
    height:100%;
    background:#fff;
    display:flex;
    flex-direction:column;
    padding:40px 0;

    section{
      width:70%;
      display:flex;
      margin:20px 0;
      flex-direction:column;
      label {
        color:#41758e;
      }

      input{
        width:100%;
        border-bottom:1px solid #41758e;
      }


      textarea {
        width:100%;
        height:300px;
        border:1px solid #41758e;
      }
    }
    button {
      width:70%;
      height:51px;
      background:#b9584b;
      color:#fff;
      font-weight: 700;
      margin-top: 10px;
      font-family: sans-serif;
      font-size: 22px;

    }
  }
}


`

const ChatContainer = styledComponents.div`

width:95%;
margin:0 auto;
margin-bottom:50px;


`;
const Button = styledComponents.button`

width:248px;
height:34px;
color:#fff;
background:#b9584b;
margin:10px 0;


`;

const InfoDetails = styledComponents.div`

display: grid;
grid-template-columns: repeat(3,1fr);
gap:20px;


@media (max-width:600px){
grid-template-columns: repeat(2,1fr);

}
svg {
  margin:5px 5px 0 0;
}

div {
  display: flex;
  flex-direction: column;
}


span {
  font-size:16px;
  line-height:21px;
  color:#000;
  font-weight:400;
  font-family: "Playfair Display",serif;
}

label{
  font-size:16px;
  line-height:29px;
  color:#000;
  font-weight:400;
  font-family: "Playfair Display",serif;
}

`;

const InnerChatWarpper = styledComponents.div`

width:80%;
height:100%;
margin: 0 auto;
padding:50px;

h2 {
  font-size:34px;
  line-height:54px;
  color:#000;
  font-weight:400;
  font-family: "Playfair Display",serif;
  margin:40px 0;
}


`;

const ChatBox = styledComponents.div`
width:100%;
background:#99999994;

`;

const PartnershipWrapper = styledComponents.div`


h1 {
  text-align: center;
  font-family: "playfair display",sans-serif;
    list-style: none;
    font-size: 56px;
    line-height:79px;
    font-weight: 400;
    margin: 40px 0;
}
p{
  font-family: "playfair display",sans-serif;
  list-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin: 20px 0 ;
  color:#000;
  width:700px;
}

@media(max-width:700px){
  p {
    width:100%;
  }
}

`;
