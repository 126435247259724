import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";

function ResetPhoneNumber({ token }) {
  const [mobile, setMobile] = useState({
    countryCode: null,
    phoneNumber: null,
  });
  const [otp, setOTP] = useState(null);

  const getPhoneNumber = (phone, data) => {
    let phoneWithoutCountryCode = phone.split(data.dialCode);
    setMobile({
      countryCode: data.dialCode,
      phoneNumber: phoneWithoutCountryCode?.[1],
    });
  };
  // console.log("mobile", mobile);

  const sendOtp = () => {
    if (mobile.phoneNumber) {
      axios
        .post(process.env.REACT_APP_CHECK_PHONE_NUMBER, {
          phoneNumber: mobile?.phoneNumber,
        })
        .then((res) => {
          if (res?.data?.status === 204) {
            const fd = new FormData();
            fd.append("To", "+" + mobile.countryCode + mobile.phoneNumber);
            fd.append("Channel", "sms");

            axios
              .post(process.env.REACT_APP_SEND_OTP, fd, {
                auth: {
                  username: process.env.REACT_APP_TWILIO_USERNAME,
                  password: process.env.REACT_APP_TWILIO_PASSWORD,
                },
                headers: { "content-type": "multipart/form-data" },
              })
              .then((res1) => {
                handleClose();
                setVerifyModal(true);
              })
              .catch((error1) => {
                toast.error(error1.response.statusText, { theme: "colored" });
              });
          } else {
            toast.warning("Phone Number already exists", { theme: "colored" });
          }
        })
        .catch((error) => {
          console.log(error, "errrrrrroorrr");
          toast.error(error.response.data.message, { theme: "colored" });
        });
    } else {
      toast.error("Please enter valid mobile number", { theme: "colored" });
    }
  };

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const handleVerifyClose = () => setVerifyModal(false);

  const verifyOtp = () => {
    if (otp) {
      const fd = new FormData();
      fd.append("To", "+" + mobile.countryCode + mobile.phoneNumber);
      fd.append("Code", otp);

      axios
        .post(process.env.REACT_APP_VERIFY_OTP, fd, {
          auth: {
            username: process.env.REACT_APP_TWILIO_USERNAME,
            password: process.env.REACT_APP_TWILIO_PASSWORD,
          },
          headers: { "content-type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.data.valid === true) {
            axios
              .put(
                process.env.REACT_APP_UPDATE_PHONE_NUMBER,
                {
                  phoneNumber: mobile.phoneNumber,
                  dialingCode: "+" + mobile.countryCode,
                },
                {
                  headers: {
                    // "Content-Type":'application/json',
                    Authorization: "b " + token,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  toast.info(res.data.message, { theme: "colored" });

                  handleVerifyClose();
                } else {
                  toast.error(res.data.message, { theme: "colored" });
                }
              })
              .catch((error) => {
                toast.error(error.response.data.message, { theme: "colored" });
              });
          } else {
            toast.error("OTP Doesn't match", { theme: "colored" });
          }
        })
        .catch((error) => {
          toast.error(error.response.statusText, { theme: "colored" });
        });
    } else {
      toast.warning("Please enter OTP", { theme: "colored" });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title>Reset Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PhoneInput
            inputStyle={{
              width: "100%",
              height: "38px",
              fontSize: "16px",
            }}
            country={"us"}
            value={""}
            onChange={(phone, data) => getPhoneNumber(phone, data)}
          />
        </Modal.Body>
        <Modal.Footer style={{ border: "none", justifyContent: "center" }}>
          <Button
            variant="primary"
            onClick={sendOtp}
            style={{ backgroundColor: "#d83567", border: "#d83567" }}
          >
            SUBMIT
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={verifyModal} onHide={handleVerifyClose} centered>
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title>Verify Phone Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="number"
            onChange={(e) => setOTP(e.target.value)}
            className="form-control"
            placeholder="Enter OTP"
          />
        </Modal.Body>
        <Modal.Footer style={{ border: "none", justifyContent: "center" }}>
          <Button
            variant="primary"
            onClick={verifyOtp}
            style={{ backgroundColor: "#d83567", border: "#d83567" }}
          >
            Verify OTP
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ResetPhoneNumber;
