import axios from "axios";
import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

export default function ForgetPassword({ token }) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const ForgetApi =
    "https://official.ibeor.com/admin/changePasswordViaEmailForAdmin";

  const updateForgetPassword = () => {
    if (newPassword && confirmPassword) {
      if (confirmPassword.length > 7) {
        if (newPassword === confirmPassword) {
          axios
            .put(
              ForgetApi,
              {
                newPassword: confirmPassword,
              },
              {
                headers: {
                  Authorization: "b " + token,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                toast.info(res.data.message, { theme: "colored" });
              } else {
                toast.error(res.data.message, { theme: "colored" });
              }
            })
            .catch((err) =>
              toast.error(err.response.data.message, { theme: "colored" })
            );
        } else {
          toast.error("Password doesn't match", { theme: "colored" });
        }
      } else {
        toast.warning("Password must be 8 character", {
          theme: "colored",
        });
      }
    } else {
      toast.warning("Please enter password", { theme: "colored" });
    }
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title>Forget password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label for="exampleInputPassword1">New Password</label>
              <input
                type="password"
                className="form-control my-2"
                placeholder="Password"
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <label for="exampleInputPassword1">Confirm Password</label>
              <input
                type="password"
                className="form-control my-2"
                placeholder="Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer style={{ border: "none", justifyContent: "center" }}>
          <Button
            variant="primary"
            type="submit"
            onClick={updateForgetPassword}
            style={{ backgroundColor: "#d83567", border: "#d83567" }}
          >
            SUBMIT
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
