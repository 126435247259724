import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styledComponents from "styled-components";
import Footer from "../Components/Footer";
import {
  Container,
  FooterItemsBox,
  HeaderLogoWrap,
  LinearColorBack,
  SingleLine,
} from "../GobalStyle";
import { iBeorLogo } from "../Utils/Images";

export default function Safety() {
  const navigate = useNavigate();

  const [size, setSize] = useState(window.innerWidth);
  const updateWindowSize = () => {
    setSize(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div>
      <LinearColorBack>
        <Container>
          <HeaderLogoWrap>
            <img src={iBeorLogo} alt="LOGO" onClick={() => navigate("/")} />
          </HeaderLogoWrap>
          <SafetyWrapper>
            <h5 style={{ margin: 0 }}>Thanks for Joining iBeor</h5>
            <p style={{ textAlign: "center" }}>
              To keep iBeor fun, we'd like you to know our social safety tips:
            </p>
          </SafetyWrapper>
        </Container>
      </LinearColorBack>
      <FooterItemsBox>
        <div>
          <h2>keep it clean:</h2>
          <span>
            Explicit or suggestive content will result in the removal of your
            iBeor Account and suspension.
          </span>
        </div>
        <div>
          <h2>Report Abuse:</h2>
          <span>
            If you see something inappropriate, please report it as soon as
            possible.
          </span>
        </div>
        <div>
          <h2>Block people:</h2>
          <span>
            If someone is bothering you, block them! They won't be able to
            contact you ever again.
          </span>
        </div>
        <div>
          <h2>Posts are public</h2>
          <span>
            Don't post anything you'll regret in the near future. Don't give out
            personal information so easily.
          </span>
        </div>
        <div>
          <h2>Be careful meeting in real </h2>
          <span>
            If you do meet in person, choose a public place or area, Like the
            mall or restaurant.
          </span>
        </div>
        <div>
          <h2>Contact Law Enforcement:</h2>
          <span>
            If you're threatened or if someone is in danger, contact your local
            police.
          </span>
        </div>

        <h1>iBeor DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS </h1>
      </FooterItemsBox>

      <SingleLine />
      {size >= 500 && <Footer />}
    </div>
  );
}

const SafetyWrapper = styledComponents.div`

h5 {
    text-align: center;
    font-family: "playfair display",sans-serif;
    list-style: none;
    font-size: 56px;
    line-height:79px;
    font-weight: 500;
    margin: 20px 0;
    color:#333;
}


p {
  font-family: "playfair display",sans-serif;
  list-style: none;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  margin: 20px 0;
  padding: 0 0 40px 0;
  color:#000;
}


`;
